import React, { useEffect } from "react";
import { Layout, Row, Col, Typography, Input, Button, Form } from "antd";
import "./style-module.scss";
import { FooterData } from "../../Content/Content";
import AOS from "aos";
import "aos/dist/aos.css";
import LogoFooter from "../../../Assets/Icons/LogoFooter";

const { Title, Paragraph } = Typography;
const { Footer } = Layout;

const AppFooter = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 0,
      easing: "ease-in-out",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Footer className="footer" id="contact">
      <div className="footer-container">
        <Row justify="space-around">
          <Col xs={24} sm={22} md={18} lg={9}>
            <div className="contact-info">
              <LogoFooter />
              <Paragraph
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                className="text mt-4"
              >
                {FooterData.description}
              </Paragraph>
              <Title
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                className="sub-heading"
                level={4}
              >
                {FooterData.text}
              </Title>
              <Paragraph
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                className="text"
              >
                <a
                  className="text"
                  href="mailto:umarwazir297@gmail.com
"
                >
                  {FooterData.email}
                </a>
              </Paragraph>
              <Title
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                className="sub-heading"
                level={4}
              >
                {FooterData.text1}
              </Title>
              <Paragraph
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                className="text"
              >
                <a className="text" href="tel:+923106231497">
                  {FooterData.phone}
                </a>
              </Paragraph>
            </div>
          </Col>
          <Col xs={24} sm={22} md={18} lg={13}>
            <div className="contact-form">
              <Title
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
                className="heading"
              >
                {FooterData.heading}
              </Title>
              <Form className="form" onSubmit={handleSubmit}>
                <Row
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  justify="space-around"
                  className="form-row"
                >
                  <Col xs={24} sm={11}>
                    <Form.Item>
                      <label htmlFor="first-name" className="common-text">
                        {FooterData.firstNameLabel}
                      </label>
                      <Input id="first-name" className="input-field" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11}>
                    <Form.Item>
                      <label htmlFor="last-name" className="common-text">
                        {FooterData.lastNameLabel}
                      </label>
                      <Input id="last-name" className="input-field" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  justify="space-around"
                  className="form-row-two"
                >
                  <Col xs={24} sm={11}>
                    <Form.Item>
                      <label htmlFor="category" className="common-text">
                        {FooterData.categoryLabel}
                      </label>
                      <Input id="category" className="input-field" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={11}>
                    <Form.Item>
                      <label htmlFor="budget" className="common-text">
                        {FooterData.budgetLabel}
                      </label>
                      <Input id="budget" className="input-field" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  justify="space-around"
                >
                  <Col xs={24} sm={24} md={24} lg={23}>
                    <Form.Item>
                      <label htmlFor="message" className="common-text">
                        {FooterData.messageLabel}
                      </label>
                      <Input.TextArea
                        id="message"
                        className="input-field"
                        rows={9}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-around">
                  <Col lg={23}>
                    <Form.Item>
                      <Button
                        data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom"
                        className="get-btn"
                        htmlType="submit"
                      >
                        {FooterData.submitButton}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
        <div>
          <Paragraph className="copy-right">
            All Rights Reserved @2023
          </Paragraph>
        </div>
      </div>
    </Footer>
  );
};
export default AppFooter;
