import React, { useEffect } from 'react';
import { Row, Col, Typography, Steps } from 'antd';
import './style-module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

const { Title, Paragraph } = Typography;
const { Step } = Steps;

const ExperienceSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 0,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <div className="experience-section">
      <div className="experience-container">
        <Title className="heading">My Work Experience</Title>
        <Row justify="space-around">
          <Col lg={6} md={18} sm={24} xs={24}>
            <div className="exp-div1">
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Title level={4}>Meta-Tech Stellar system</Title>
                <Paragraph className="text">Nov 2023 - Current</Paragraph>
                <Row>
                  <Col lg={0} md={18} sm={24} xs={24}>
                  <div
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <Title level={4}>JuniorFrontend Developer</Title>
                    <Paragraph className="text">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                    </Paragraph>
                  </div>
                  </Col>
                </Row>
              </div>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Title level={4}>Poiesis Solution</Title>
                <Paragraph className="text">Sep 2022 - oct 2023</Paragraph>
                <Row>
                  <Col lg={0} md={18} sm={18} xs={18}>
                  <div
                    data-aos="fade-up"
                    data-aos-anchor-placement="bottom-bottom"
                  >
                    <Title level={4}>JuniorFrontend Developer</Title>
                    <Paragraph className="text">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                    </Paragraph>
                  </div>
                  </Col>
                </Row>
              </div>
              {/* <div
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Title level={4}>Funsol</Title>
                <Paragraph className="text">Sep 2017 - Aug 2018</Paragraph>
              </div> */}
            </div>
          </Col>
          <Col lg={4} md={0} sm={0} xs={0}>
            <div className="centered-stepper">
              <Steps
                className="stepper"
                progressDot
                direction="vertical"
              >
                <Step
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                />
                <Step
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                />
                {/* <Step
                  data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                /> */}
              </Steps>
            </div>
          </Col>
          <Col lg={8} md={0} sm={0} xs={0}>
            <div className="exp-div2">
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Title level={4}>JuniorFrontend Developer</Title>
                <Paragraph className="text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                </Paragraph>
              </div>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Title level={4}>Junior Frontend Developer</Title>
                <Paragraph className="text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                </Paragraph>
              </div>
              {/* <div
                data-aos="fade-up"
                data-aos-anchor-placement="bottom-bottom"
              >
                <Title level={4}>Web Designer</Title>
                <Paragraph className="text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                </Paragraph>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ExperienceSection;
