import React from "react";
import "./style-module.scss";
import WhatsApp from "../../../../Assets/Icons/WhatsApp";
import GitIcon from "../../../../Assets/Icons/GithubIcon";
import FacebookIcon from "../../../../Assets/Icons/FacebookIcon";
import UpworkIcon from "../../../../Assets/Icons/UpworkIcon";

const SocialMediaIcons = () => {
  return (
    <div className="social-media-icons">
      <a
        className="fiver"
        href="https://www.upwork.com/freelancers/~0199b11ab95b8121d3"
        target="_blank"
        rel="noreferrer"
      >
        <UpworkIcon />
      </a>
      <a
        href="https://github.com/umarwazir297"
        target="_blank"
        rel="noreferrer"
      >
        <GitIcon />
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=923106231497
          "
        target="_blank"
        rel="noreferrer"
      >
        <WhatsApp />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
