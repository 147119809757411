export default function UpworkIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 0H26.25C28.3192 0 30 1.6808 30 3.75V26.25C30 28.3192 28.3192 30 26.25 30H3.75C1.6808 30 0 28.3192 0 26.25V3.75C0 1.6808 1.6808 0 3.75 0ZM20.9194 18.1594C18.7044 17.7435 17.9259 14.8123 19.136 12.9111C19.7601 11.9305 20.6031 11.5647 21.5826 11.5647C23.2768 11.5647 24.5893 12.9174 24.5893 14.8929C24.5893 16.8683 23.2701 18.221 21.5826 18.221C21.3517 18.221 21.1304 18.199 20.9194 18.1594ZM16.3527 13.4066C16.3527 13.41 16.3482 13.4112 16.3466 13.4082C15.8871 12.5358 15.5263 11.4151 15.2287 10.3145C15.1306 9.95173 14.8039 9.69643 14.4281 9.69643H13.2241C11.9156 9.69643 10.8549 10.7572 10.8549 12.0656V14.9263C10.8549 16.8281 9.99107 18.2344 8.29687 18.2344C6.60268 18.2344 5.6317 16.8348 5.6317 14.933L5.64712 10.9175C5.64969 10.2476 5.10733 9.70312 4.4374 9.70312V9.70312C3.76929 9.70312 3.22768 10.2447 3.22768 10.9128V14.933C3.22768 16.4598 3.72321 17.846 4.62723 18.8304C5.55804 19.8482 6.82366 20.3839 8.29687 20.3839C11.2232 20.3839 13.2656 18.1406 13.2656 14.9263V14.9263C13.2656 14.3886 14.2507 14.2185 14.486 14.702C14.7748 15.2954 15.1157 15.9057 15.5157 16.497C15.625 16.6587 15.6708 16.856 15.6372 17.0484L14.6446 22.7313C14.5155 23.4705 15.0844 24.1473 15.8347 24.1473V24.1473C16.4268 24.1473 16.9317 23.7182 17.0271 23.1339L17.542 19.9802C17.6201 19.5015 18.1994 19.2785 18.6161 19.5268V19.5268C19.4397 20.0491 20.3839 20.3438 21.3549 20.3705H21.5826C24.6027 20.3705 27 18.0335 27 14.8862C27 11.7388 24.596 9.3817 21.5826 9.3817C18.5426 9.3817 16.835 11.3703 16.3528 13.4059C16.3527 13.4061 16.3527 13.4064 16.3527 13.4066V13.4066Z"
        fill="url(#paint0_linear_2512_8)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2512_8"
          x1="0"
          y1="15"
          x2="30"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4776E6" />
          <stop offset="1" stop-color="#8E54E9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
