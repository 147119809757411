import React , {useEffect} from 'react';
import { Row, Col, Card, Typography } from 'antd';
import{ ServiceData} from '../../../Content/Content'; 
import './style-module.scss'
import AOS from 'aos';
import 'aos/dist/aos.css';

const { Title, Paragraph } = Typography;

const Service = () => {

 useEffect(() => {
    AOS.init({
      duration: 1500, 
      offset: 0,  
      easing: 'ease-in-out',
    });
  }, []);


  return (
    <div className="service-section"  id="services">
        <div className="service-container">
            <Row justify='space-around'>
              <Col xs={24} sm={18}  md={23} lg={23} >
                <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                  <Title  level={5} className='sub-heading' >Services I Provide</Title>
                  <Title  className='heading'>My Services</Title>
                </div>
              </Col>
              {ServiceData.map((service, index) => (
                <Col key={index} xs={24} sm={18} md={11} lg={7}>
                    <Card className='service-card' data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" >
                    {service.icon}
                    <Title level={3} className='card-title'  >{service.title}</Title>
                    <Paragraph className='service-text' >{service.description}</Paragraph>
                    </Card>
                </Col>
              ))}
            </Row>
        </div>
    </div>
  );
};

export default Service;
