import React, { useState } from "react";
import { Menu, Button, Drawer, Col, Row, Typography } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Image } from "react-bootstrap";
import Logo from "../../../Assets/Icons/Logo";

const { Title } = Typography;

const NavData = [
  {
    key: "1",
    title: "Home",
    sectionId: "home", // Assign an ID for the Home section
  },
  {
    key: "2",
    title: "About",
    sectionId: "about", // Assign an ID for the About section
  },
  {
    key: "3",
    title: "Services",
    sectionId: "services", // Assign an ID for the Services section
  },
  {
    key: "4",
    title: "Resume",
    sectionId: "resume", // Assign an ID for the Resume section
  },
  {
    key: "5",
    title: "Project",
    sectionId: "project", // Assign an ID for the Project section
  },
  {
    key: "6",
    title: "Contact Us",
    sectionId: "contact", // Assign an ID for the Contact Us section
  },
];

export default function NavbarData() {
  const [visible, setVisible] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("1");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleMenuItemClick = (key, sectionId) => {
    setActiveMenuItem(key);

    // Scroll to the clicked section
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Row>
        <Col xs={19} sm={20} md={20} lg={6} className="logo-holder">
          <div className="logo">
            <Logo />
          </div>
        </Col>
        <Col xs={0} sm={0} md={0} lg={18} className="menu-holder">
          <Menu
            mode="horizontal"
            selectedKeys={[activeMenuItem]}
            className="d-flex justify-content-end"
          >
            {NavData.map((item) => (
              <Menu.Item
                key={item.key}
                className={activeMenuItem === item.key ? "active" : ""}
                onClick={() => handleMenuItemClick(item.key, item.sectionId)}
              >
                {item.title}
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <Col xs={5} sm={4} md={2} lg={0}>
          <Button type="primary" className="menubar-btn" onClick={showDrawer}>
            <MenuOutlined />
          </Button>
        </Col>
      </Row>
      <Drawer title="Menu" placement="left" onClose={onClose} open={visible}>
        <Menu mode="vertical" defaultSelectedKeys={[activeMenuItem]}>
          {NavData.map((item) => (
            <Menu.Item
              key={item.key}
              onClick={() => handleMenuItemClick(item.key, item.sectionId)}
            >
              {item.title}
            </Menu.Item>
          ))}
        </Menu>
      </Drawer>
    </>
  );
}
