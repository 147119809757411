import React , {useEffect}  from 'react';
import { Row, Col, Button, Progress, Typography } from 'antd';
import {SkillContentData , SkillData} from '../../../Content/Content';
import './style-module.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

const { Title, Paragraph } = Typography;

export default function Index() {

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      offset: 0,  
      easing: 'ease-in-out',
    });
  }, []);


  return (
    <>
      <div className="skill-section" id='resume'>
        <div className="skill-container">
          <Row justify="space-around">
            <Col xs={24} sm={18} md={11} lg={9}>
              <div >
                <Title data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="sub-heading" level={5}>
                  {SkillContentData.subHeading}
                </Title>
                <Title data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="heading">{SkillContentData.heading}</Title>
                <Paragraph data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="text">
                 {SkillContentData.text}
                </Paragraph>
                <Button data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="skill-btn"> {SkillContentData.buttonText} </Button>
              </div>
            </Col>
            <Col xs={24} sm={18} md={11} lg={11}>
              <div className="progress-holder">
                {SkillData.map((skill, index) => (
                  <React.Fragment key={index}>
                    <Title data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"  className="progress-title" level={5}>
                      {skill.title}
                    </Title>
                    <Progress data-aos="fade-up" data-aos-anchor-placement="bottom-bottom"  percent={skill.percentage} />
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
