import React from 'react'
import Navbar from '../../Component/Common/Navbar/Navbar'
import Footer from '../../Component/Common/Footer/Footer'
import Hero from '../../Component/PagesComponent/Home/Hero/Hero'
import Services from '../../Component/PagesComponent/Home/Services/Index'
import Skill from '../../Component/PagesComponent/Home/Skill/Index'
import Work from '../../Component/PagesComponent/Home/Work/Index'
import Project from '../../Component/PagesComponent/Home/Project/Index'
import Testimonial from '../../Component/PagesComponent/Home/Testimonial/Index'
import Dots from '../../Component/PagesComponent/Home/Dot/Index';

export default function Home() {
  return (
    <div>
        <Navbar/>
        <Hero />
        <Services/>
        <Dots/>
        <Skill/>
        <Work/>
        <Project/>
        <Testimonial/>
        <Footer/>
    </div>
  )
}
