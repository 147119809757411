import './App.css';
import Home from './Pages/Home/Home'



function App() {
  return (
    <>
      <Home/>
    </>
  );
}

export default App;
