import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Button, Image } from "antd";
import heroImg from "../../../../Assets/Images/hero-img.png";
import "./style-module.scss";
import { HeroData } from "../../../Content/Content";
import AOS from "aos";
import Resume from "../../../../Assets/Images/Umar Wazir CV.pdf";
import "aos/dist/aos.css";
import { Container } from "react-bootstrap";

const { Title, Paragraph } = Typography;

export default function Hero() {
  useEffect(() => {
    AOS.init({
      duration: 100,
      offset: 120,
      easing: "ease-in-out",
    });
  }, []);

  const [letters, setLetters] = useState([]);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);
  const [reverseAnimation, setReverseAnimation] = useState(false);
  const [animationPaused, setAnimationPaused] = useState(false);

  useEffect(() => {
    const subtitleLetters = HeroData.subtitle.split("");
    setLetters(subtitleLetters);
    const interval = setInterval(() => {
      if (!animationPaused) {
        if (reverseAnimation) {
          setCurrentLetterIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : 0
          );
        } else {
          setCurrentLetterIndex((prevIndex) =>
            prevIndex < subtitleLetters.length - 1
              ? prevIndex + 1
              : subtitleLetters.length - 1
          );
        }
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [reverseAnimation, animationPaused]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor);
    }, 500);

    return () => {
      clearInterval(cursorInterval);
    };
  }, []);

  useEffect(() => {
    if (currentLetterIndex === letters.length - 1) {
      setReverseAnimation(true);
      setAnimationPaused(true);
      setTimeout(() => {
        setAnimationPaused(false);
      }, 1000);
    } else if (currentLetterIndex === 0) {
      setReverseAnimation(false);
      setAnimationPaused(true);
      setTimeout(() => {
        setAnimationPaused(false);
      }, 1000);
    }
  }, [currentLetterIndex, letters.length]);

  return (
    <div className="hero-section" id="about">
      <Container className="hero-container">
        <Row justify="space-around align-items-center">
          <Col xs={24} sm={24} md={16} lg={12}>
            <div data-aos="fade-up-right" className="hero-content">
              <Title className="heading">
                {HeroData.Title} <br />{" "}
                <span>{letters.slice(0, currentLetterIndex + 1).join("")}</span>
                <span className={`cursor ${showCursor ? "show" : ""}`}>|</span>
              </Title>
              <Paragraph className="hero-text">{HeroData.paragraph}</Paragraph>
              <div className="hero-btn-holder">
                <Button
                  className="hero-btn"
                  href="https://www.fiverr.com/devaliasjad?public_mode=true"
                  target="_blank"
                >
                  {HeroData.buttonText}
                </Button>
                <Button className="hero-btn-download" href={Resume}>
                  Download Resume
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={16} lg={12}>
            <div
              data-aos="fade-down-left"
              className="hero-image-container text-end "
            >
              <Image
                src={heroImg}
                alt="Hero"
                className="hero-img"
                preview={false}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
