import React , {useRef , useEffect} from 'react';
import { Row ,  Col, Typography, Card, Rate, Image, Carousel } from 'antd';
import {TestimonialData} from '../../../Content/Content'
import PrevIcon from '../../../../Assets/Icons/PrevIcon'
import NextIcon from '../../../../Assets/Icons/NextIcon'
import './style-module.scss';
import carouselSettings from '../../../CarouselSetting/carouselSettings'
import AOS from 'aos';
import 'aos/dist/aos.css';


const { Title, Paragraph } = Typography;


const TestimonialSection = () => {

    const carouselRef = useRef(null);
    
    useEffect(() => {
      AOS.init({
        duration: 1000, 
        offset: 0,  
        easing: 'ease-in-out',
      });
    }, []);

  return (
    <div className="testimonial-section">
      <div className="testimonial-container">
        <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" >
          <Title level={5} className="subheading">
            Testimonial
          </Title>
          <Title className="heading">Awesome Clients Feedback</Title>
        </div>
        <Row justify='space-around' >
          <Col lg={22} >
          <Carousel  ref={carouselRef} {...carouselSettings} className='carousel' >
          {TestimonialData.map((testimonial, index) => (
            <Col data-aos="fade-up"  data-aos-anchor-placement="center-bottom" key={index} xs={23} sm={22} md={20} lg={23}>
              <Card   className="testimonial-card">
                <Rate className='rate-star' defaultValue={testimonial.rating} />
                <Paragraph className="testimonial-text">{testimonial.text}</Paragraph>
                <div className="testimonial-author">
                  <Image
                    src={testimonial.imageSrc}
                    alt={`Testimonial ${index + 1}`}
                    preview={false}
                    className="testimonial-image"
                  />
                  <div>
                    <Title className='sub-heading'  level={5}>{testimonial.title}</Title>
                    <Paragraph className='text' >{testimonial.company}</Paragraph>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Carousel>
          </Col>
        </Row>
        <div data-aos="fade-up"  data-aos-anchor-placement="center-bottom" className="team-btn-holder">
            <div className='team-btn' onClick={() => carouselRef.current.prev()}> <PrevIcon /> </div>
            <div className='team-btn' onClick={() => carouselRef.current.next()}><NextIcon /></div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
