import React from "react";
import "./Navbar.scss";
import NavbarData from "./NavbarData";
import { Layout } from "antd";
import { Container } from "react-bootstrap";

const { Header } = Layout;

const Navbar = () => {
  return (
    <>
      <div className="navbar-section">
        <Container>
          <Layout className="layout">
            <Header className="header">
              <NavbarData />
            </Header>
          </Layout>
        </Container>
      </div>
    </>
  );
};

export default Navbar;
