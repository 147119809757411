import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Typography, Image, Carousel, Button, Tabs } from 'antd';
import { ProjectData } from '../../../Content/Content';
import PrevIcon from '../../../../Assets/Icons/PrevIcon';
import NextIcon from '../../../../Assets/Icons/NextIcon';
import './style-module.scss';
import carouselSettings from '../../../CarouselSetting/carouselSettings';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import FinImg2 from '../../../../Assets/Images/fin.png';

const { Title } = Typography;

const ProjectSection = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      offset: 0,
      easing: 'ease-in-out',
    });
  }, []);

  const carouselRef = useRef(null);
  const [activeTab, setActiveTab] = useState('webDesign');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="project-section" id="project">
      <div className="project-container">
        <Row justify="space-around">
          <Col lg={22}>
            <Title data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="sub-heading" level={4}>
              Works & Portfolio
            </Title>
            <Title data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="heading">
              My Projects
            </Title>
            {/* <div data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" className="tab-buttons"> */}
              {/* <Button
                className={activeTab === 'webDesign' ? 'active-tab-button' : 'tab-button'}
                onClick={() => handleTabChange('webDesign')}
              >
                Web Design
              </Button> */}
              {/* <Button
                className={activeTab === 'wireframe' ? 'active-tab-button' : 'tab-button'}
                onClick={() => handleTabChange('wireframe')}
              >
                Wireframe
              </Button>
              <Button
                className={activeTab === 'uiuxDesign' ? 'active-tab-button' : 'tab-button'}
                onClick={() => handleTabChange('uiuxDesign')}
              >
                UI/UX Design
              </Button> */}
            {/* </div> */}
          </Col>
        </Row>
        <Row justify="space-around">
          <Col lg={22}>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              {ProjectData.map((project) => (
                <Tabs.TabPane tab={project.tabTitle} key={project.tabKey}>
                <Carousel ref={carouselRef} {...carouselSettings}>
                  {project.images.map((image, index) => (
                    <a href={image.link} target="_blank" rel="noopener noreferrer" key={index}>
                      <Image src={image.src} alt="" preview={false} className="carousel-img" />
                    </a>
                  ))}
                </Carousel>
              </Tabs.TabPane>              
              ))}
            </Tabs>
            <Button data-aos="fade-right" className="project-btn">
              See more
            </Button>
            <div className="team-btn-holder">
              <div className="carousel-btn-left" onClick={() => carouselRef.current.prev()}>
                <PrevIcon />
              </div>
              <div className="carousel-btn-right" onClick={() => carouselRef.current.next()}>
                <NextIcon />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProjectSection;
