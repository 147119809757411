export default function ServiceIcon1() {
  return (
    <svg
      width="42"
      height="61"
      viewBox="0 0 42 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.0427 0.970673H5.64325C3.06261 0.970673 0.951172 3.07273 0.951172 5.66275V56.2903C0.951172 58.871 3.05322 60.9824 5.64325 60.9824H37.0521C39.6327 60.9824 41.7441 58.8803 41.7441 56.2903V5.65337C41.7347 3.07273 39.6327 0.970673 37.0427 0.970673ZM3.29721 9.64164H39.3887V51.129H3.29721V9.64164ZM5.64325 3.31671H37.0521C38.3471 3.31671 39.3981 4.36774 39.3981 5.66275V7.30498H3.29721V5.65337C3.29721 4.36774 4.34824 3.31671 5.64325 3.31671ZM37.0427 58.627H5.64325C4.34824 58.627 3.29721 57.576 3.29721 56.2809V53.4657H39.3887V56.2809C39.3887 57.576 38.3377 58.627 37.0427 58.627Z"
        fill="url(#paint0_linear_78_321)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_78_321"
          x1="0.951172"
          y1="30.9765"
          x2="41.7441"
          y2="30.9765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#4776E6" />
          <stop offset="1" stop-color="#8E54E9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
