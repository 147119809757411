import ComputerIcon from "../../Assets/Icons/ServiceIcon";
import ComputerIcon1 from "../../Assets/Icons/ServiceIcon1";
import testiImg from "../../Assets/Images/testi-img.png";
import GymImg from "../../Assets/Images/gym.png";
import FinImg2 from "../../Assets/Images/fin.png";
import blogImg from "../../Assets/Images/blog.png";
import FinImg from "../../Assets/Images/fin2.png";
import PortImg from "../../Assets/Images/port.png";
import ApplianceImg from "../../Assets/Images/appliance.png";
import ElectricImg from "../../Assets/Images/electric.png";

const HeroData = {
  Title: "Hello, I’m Umar Wazir",
  subtitle: "Frontend Developer",
  paragraph:
    "Passionately devoted to crafting cutting-edge, pixel-perfect, and stunningly beautiful interfaces, I intuitively implement UI that reverberates with excellence.",
  buttonText: "Hire Me",
};

//  Services Data

const ServiceData = [
  {
    icon: <ComputerIcon />,
    title: "WordPress Website ",
    description:
      "Let's bring your WordPress dreams to life! We offer a range of casual and engaging services that'll make your website shine.",
  },
  {
    icon: <ComputerIcon1 />,
    title: "Custom Website",
    description:
      "Crafting custom websites tailored to your unique vision and needs. We transform ideas into stunning, user-friendly digital experiences.",
  },
  {
    icon: <ComputerIcon />,
    title: "UI/UX Designing",
    description:
      "Passionate UI/UX designer creating intuitive and visually engaging digital interfaces for seamless user experiences.",
  },
  {
    icon: <ComputerIcon1 />,
    title: "Maintenance & Deployment",
    description:
      "Ensuring smooth maintenance and deployment, keeping your digital solutions running seamlessly and up-to-date.",
  },
];

// SkillData.js

const SkillContentData = {
  subHeading: "See All My Skills",
  heading: "My Working Skills",
  text: "Proficient in frontend development, UI/UX design, and agile project management. Skilled in HTML, CSS, JavaScript, and React, crafting visually appealing digital experiences. Proficient in Git and project management tools, committed to staying current with emerging technologies.",
  buttonText: "View More",
};

const SkillData = [
  {
    title: "HTML / CSS / JavaScript",
    percentage: 80,
    description: "Lorem Ipsum is simply dummy text...",
  },
  {
    title: "Bootstrap / React",
    percentage: 80,
    description: "Lorem Ipsum is simply dummy text...",
  },
  {
    title: "Material UI / Ant Design / React Bootstrap",
    percentage: 75,
    description: "Lorem Ipsum is simply dummy text...",
  },
  {
    title: "Firebase / Vercel",
    percentage: 80,
    description: "Lorem Ipsum is simply dummy text...",
  },
  {
    title: "GitHub",
    percentage: 70,
    description: "Lorem Ipsum is simply dummy text...",
  },
];
// ExperienceData.js

const experienceData = [
  {
    id: 1,
    companyName: "Self-Employed, NYC",
    date: "Sep 2020 - Aug 2023",
    jobTitle: "Visual Designer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 2,
    companyName: "Poiesis Solution",
    date: "Sep 2018 - Aug 2020",
    jobTitle: "UI/UX Designer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 3,
    companyName: "Funsol",
    date: "Sep 2017 - Aug 2018",
    jobTitle: "Web Designer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

//  Project Data

const ProjectData = [
  {
    tabKey: "webDesign",
    images: [
      { src: GymImg, link: "https://fitness-dev-ali-asjad.vercel.app/" },
      { src: FinImg, link: "https://mobileapps-lime.vercel.app/" },
      { src: ApplianceImg, link: "https://ecommerce-mu-sand.vercel.app/" },
      { src: blogImg, link: "https://dev-ali-asjad-blog-website.vercel.app/" },
      { src: PortImg, link: "https://example.com/image4" },
      {
        src: ElectricImg,
        link: "https://electric-websit-dev-aliasjad.vercel.app/",
      },
      { src: FinImg2, link: "https://fintechmopileapp.vercel.app/" },
    ],
  },
];
//   {
//     tabKey: 'wireframe',
//     images: [
//       { src: GymImg, link: 'https://fitness-dev-ali-asjad.vercel.app/' },
//       { src: FinImg, link: 'https://mobileapps-lime.vercel.app/' },
//       { src: ApplianceImg, link: 'https://ecommerce-mu-sand.vercel.app/' },
//       { src: PortImg, link: 'https://example.com/image4' },
//       { src: ElectricImg, link: 'https://electric-websit-dev-aliasjad.vercel.app/' },
//       { src: FinImg2, link: 'https://fintechmopileapp.vercel.app/' },
//     ],
//   },
//   {
//     tabKey: 'uiuxDesign',
//     images: [
//       { src: GymImg, link: 'https://fitness-dev-ali-asjad.vercel.app/' },
//       { src: FinImg, link: 'https://mobileapps-lime.vercel.app/' },
//       { src: ApplianceImg, link: 'https://ecommerce-mu-sand.vercel.app/' },
//       { src: PortImg, link: 'https://example.com/image4' },
//       { src: ElectricImg, link: 'https://electric-websit-dev-aliasjad.vercel.app/' },
//       { src: FinImg2, link: 'https://fintechmopileapp.vercel.app/' },
//     ],
//   },
// ];

//    Project Data

const TestimonialData = [
  {
    rating: 5,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to  make a type specimen book.",
    imageSrc: testiImg,
    title: "Henry, Arthur ",
    company: "Ceo , Food Express",
  },
  {
    rating: 5,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to  make a type specimen book.",
    imageSrc: testiImg,
    title: "Henry, Arthur ",
    company: "Ceo , Food Express",
  },
  {
    rating: 5,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to  make a type specimen book.",
    imageSrc: testiImg,
    title: "Henry, Arthur ",
    company: "Ceo , Food Express",
  },
  {
    rating: 5,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to  make a type specimen book.",
    imageSrc: testiImg,
    title: "Henry, Arthur ",
    company: "Ceo , Food Express",
  },
];

//   Footer Data

const FooterData = {
  logoText: "PORT",
  text: "Email",
  text1: "Phone",
  description:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.",
  email: "umarwazir297@gmail.com ",
  phone: "+92 310 6231497",
  heading: "Let's Work Together",
  firstNameLabel: "First Name",
  lastNameLabel: "Last Name",
  categoryLabel: "Category",
  budgetLabel: "Budget",
  messageLabel: "Message",
  submitButton: "Contact Me",
};

export {
  HeroData,
  ServiceData,
  SkillContentData,
  SkillData,
  experienceData,
  ProjectData,
  TestimonialData,
  FooterData,
};
